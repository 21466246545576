import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '@environment/environment';
import { IDefaultPaginatedParams, IFilterParams, ISortingParams } from '@interfaces/default.interfaces';
import { __values } from 'tslib';
import { LocalStorageService } from '@services/localStorage/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private expired = new Subject<boolean>();
  /**
   * Base URL for all requests made through this
   * service.
   */
  public apiUrl: string;

  /**
   * HTTP headers applied to all requests made through this
   * service.
   */
  public httpHeaders = new HttpHeaders();

  /**
   * Creates a new HttpApiService instance and initializes it.
   */
  constructor(
    public http: HttpClient,
    private localStorageService: LocalStorageService
  ) {
    this.apiUrl = environment.API_URL;
    this.setDefaultHeaders();
  }

  /**
   * Sets the default headers and values for all requests made
   * through this service.
   */
  public setDefaultHeaders(): void {
    this.httpHeaders = this.httpHeaders.set('Content-Type', 'application/json').set('Accept', 'application/json');
  }

  /**
   * Returns all headers and values currently beign used by the service.
   */
  public getHeaders(): HttpHeaders {
    return this.httpHeaders;
  }

  /**
   * Sets a header value for a given name. If the header name already
   * exists, its value is replaced with the given value.
   *
   * @param headerName The header name.
   * @param headerValue Provides the value to set or overide for a given name.
   */
  public setHeader(headerName: string, headerValue: string | string[]): void {
    this.httpHeaders = this.httpHeaders.set(headerName, headerValue);
  }

  /**
   * Checks for existence of a header by a given name. Then deletes all
   * header values for the given name, or logs an error in development mode.
   *
   * @param headerName The header name.
   */
  public deleteHeader(headerName: string): void {
    if (this.httpHeaders.has(headerName)) {
      this.httpHeaders = this.httpHeaders.delete(headerName);
    } else {
      console.warn('Tried to delete a header that does not exist.');
    }
  }

  /**
   * Constructs a `GET` request that interprets the body as a JSON object and returns it as a JSON object.
   * @param url The endpoint URL.
   * @param params The HTTP GET parameters.
   * @return An `Observable` of the response body as a JSON object.
   */
  public get(url: string, params?: HttpParams): Observable<Object>;

  /**
   * Constructs a `GET` request that interprets the body as T and returns it as T.
   * @param url The endpoint URL.
   * @param params The HTTP GET parameters.
   * @return An `Observable` of the response body as a JSON object.
   */
  public get<T>(url: string, params?: HttpParams): Observable<T>;

  public get<T = void>(url: string, params?: HttpParams) {
    const opts = {
      headers: this.httpHeaders,
      params: (params ? params : null) as HttpParams,
    };
    if (params) {
      opts.params = params;
    }

    return this.http.get<T>(this.apiUrl + url, opts).pipe();
  }

  public download(url: string, params?: HttpParams): Observable<any> {
    const opts: Object = {
      headers: this.httpHeaders,
      params: (params ? params : null) as HttpParams,
      responseType: 'blob',
    };

    if (params) {
      (opts as any).params = params;
    }

    return this.http.get(this.apiUrl + url, opts).pipe();
  }
  /**
   * Constructs a `POST` request that interprets the body as a JSON object and returns it as a JSON object.
   * @param url The endpoint URL.
   * @param body The content to replace with.
   * @return An `Observable` of the response, with the response body as a JSON object.
   */
  post(
    url: string,
    body: any | null,
    options?: {
      headers?: HttpHeaders | { [header: string]: string | string[] };
      observe?: 'body';
      params?: HttpParams | { [param: string]: string | string[] };
      reportProgress?: boolean;
      responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
      withCredentials?: boolean;
    }
  ): Observable<Object>;

  /**
   * Constructs a `POST` request that interprets the body as T and returns it as T.
   * @param url The endpoint URL.
   * @param body The content to replace with.
   * @return An `Observable` of the response, with the response body as T.
   */
  public post<T>(url: string, body?: any): Observable<T>;

  public post<T = void>(url: string, body?: any) {
    let postBody = body;
    const opts = {
      headers: this.httpHeaders,
    };

    if (!postBody) {
      postBody = {};
    }

    return this.http.post<T>(this.apiUrl + url, postBody, opts).pipe();
  }

  /**
   * Constructs a `POST` request that interprets the body as a JSON object and returns it as a JSON object.
   * @param url The endpoint URL.
   * @param body The content to replace with.
   * @return An `Observable` of the response, with the response body as a JSON object.
   */
  postFile(
    url: string,
    body: any | null
  ): // options?: {
  //   headers?: HttpHeaders | { [header: string]: string | string[] };
  //   observe?: 'body';
  //   params?: HttpParams | { [param: string]: string | string[] };
  //   reportProgress?: boolean;
  //   responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
  //   withCredentials?: boolean;
  // }
  Observable<Object>;

  /**
   * Constructs a `POST` request that interprets the body as T and returns it as T.
   * @param url The endpoint URL.
   * @param body The content to replace with.
   * @return An `Observable` of the response, with the response body as T.
   */
  public postFile<T>(url: string, body?: any): Observable<T>;

  public postFile<T = void>(url: string, body?: any) {
    const token = this.localStorageService.getToken();
    const params = new HttpParams();
    let headersFile = new HttpHeaders();
    headersFile = headersFile.append('Authorization', 'BEARER' + '  ' + token);

    let postBody = body;
    const opts = {
      headers: headersFile,
      params: (params ? params : null) as HttpParams,
    };

    if (!postBody) {
      postBody = {};
    }

    return this.http.post<T>(this.apiUrl + url, postBody, opts).pipe();
  }

  /**
   * Constructs a `PUT` request that interprets the body as a JSON object and returns it as a JSON object.
   * @param url The endpoint URL.
   * @param body The resources to add/update.
   * @return An `Observable` of the response, with the response body as a JSON object.
   */
  public put(url: string, body?: any): Observable<Object>;

  /**
   * Constructs a `PUT` request that interprets the body as T and returns it as T.
   * @param url The endpoint URL.
   * @param body The resources to add/update.
   * @return An `Observable` of the response, with the response body as a JSON object.
   */
  public put<T>(url: string, body?: any): Observable<T>;

  public put<T = void>(url: string, body?: any) {
    let putBody = body;
    const opts = {
      headers: this.httpHeaders,
    };

    if (!putBody) {
      putBody = {};
    }

    return this.http.put<T>(this.apiUrl + url, putBody, opts).pipe();
  }

  /**
   * Constructs a `DELETE` request that interprets the body as a JSON object and returns it as a JSON object.
   * @param url The endpoint URL.
   * @return An `Observable` of the response, with the response body of type `Object`.
   */
  public delete(url: string, body: any): Observable<Object>;

  /**
   * Constructs a `DELETE` request that interprets the body as T and returns it as T.
   * @param url The endpoint URL.
   * @return An `Observable` of the response, with the response body as T.
   */
  public delete<T>(url: string, body?: any): Observable<T>;

  public delete<T = void>(url: string, body?: any) {
    const opts = {
      headers: this.httpHeaders,
      body: body ?? {},
    };

    return this.http.delete<T>(this.apiUrl + url, opts).pipe();
  }

  tokenExpired() {
    return this.expired.asObservable();
  }

  buildParams(params: any) {
    let httpParams = new HttpParams();
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        switch (key) {
          case 'filter':
            if (params.filter && params.filter.length > 0) {
              params.filter.forEach(
                (filterObj: { [x: string]: { toString: () => any }; hasOwnProperty: (arg0: string) => any }) => {
                  for (const filterKey in filterObj) {
                    if (filterObj.hasOwnProperty(filterKey)) {
                      const filterValue = filterObj[filterKey].toString();
                      httpParams = httpParams.append(`filter[${filterKey}]`, filterValue);
                    }
                  }
                }
              );
            }
            break;
          case 'sorting':
            if (params.sorting && params.sorting.length > 0) {
              params.sorting.forEach(
                (sortingObj: { [x: string]: { toString: () => any }; hasOwnProperty: (arg0: string) => any }) => {
                  for (const sortingKey in sortingObj) {
                    if (sortingObj.hasOwnProperty(sortingKey)) {
                      const sortingValue = sortingObj[sortingKey].toString();
                      httpParams = httpParams.append(`sorting[${sortingKey}]`, sortingValue);
                    }
                  }
                }
              );
            }
            break;
          default:
            httpParams = httpParams.set(key, params[key].toString());
        }
      }
    }
    return httpParams;
  }
}
